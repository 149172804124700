import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import Img from "gatsby-image"
// import SubscriptionForm from "../components/SubscriptionForm"
import { MDXRenderer } from "gatsby-plugin-mdx"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid

  const tweet = `https://twitter.com/share?text=${post.frontmatter.title} via @rleggos&url=${data.site.siteMetadata.siteUrl}/${post.frontmatter.path}`

  const shareOnTwitter = e => {
    e.preventDefault()
    window.open(e.target.href, "twitter-share", "width=550,height=235")
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={featuredImgFluid}
      />
      <article className="wrapper">
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
            {post.frontmatter.lastUpdatedDate &&
              ` - last updated ${post.frontmatter.lastUpdatedDate}`}
          </p>
          <Img fluid={featuredImgFluid} className="featured-image" alt="" />
        </header>
        <MDXRenderer>{post.body}</MDXRenderer>
        <section className="article-footer">
          <p>
            <strong>Did you find this post useful?</strong>
          </p>
          <p>
            If this post helped you, I would be grateful if you shared it on
            Twitter! You can{" "}
            <a href={tweet} onClick={e => shareOnTwitter(e)}>
              click here to share this post on Twitter.
            </a>
          </p>
          <p>
            If you have any questions or have anything to add, I would love it
            if you tweeted me at{" "}
            <a
              href="https://twitter.com/rleggos"
              target="_blank"
              rel="noopener noreferrer"
            >
              @rleggos
            </a>{" "}
            to continue the conversation.
          </p>
        </section>
        <section
          className="featured-image-credit"
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.featuredImageCredit,
          }}
        />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          {/* <SubscriptionForm /> */}
          <Bio />
        </footer>
      </article>

      <nav aria-label="Previous and Next Posts" className="wrapper">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        path
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImageCredit
        lastUpdatedDate(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
